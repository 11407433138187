import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { KeycloakService } from '../keycloak/service/keycloak.service';

import { CookieService } from './cookie.service';

export const skipStartGuard: CanActivateFn = async () => {
  const keycloakService = inject(KeycloakService);
  const cookieService = inject(CookieService);
  const router = inject(Router);

  const isAuthenticated = await keycloakService.isAuthenticated();
  const hasCookies = await cookieService.hasCookies();

  if (isAuthenticated && hasCookies) {
    router.navigate(['tabs', 'map']);

    return false;
  }

  return true;
};
