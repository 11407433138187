import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

/** @deprecated repace by @svnl/shared version */
export abstract class BaseService {
  protected endpointUrl: string;

  protected constructor(
    protected httpClient: HttpClient,
    protected path: string,
  ) {
    this.endpointUrl = `${environment.serverUrl}/${this.path}`;
  }
}
