import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { CookieService } from '@service/cookie.service';
import { SettingsService } from '@service/settings.service';

@Component({
  selector: 'svvp-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonRouterOutlet, IonApp],
})
export class AppComponent implements AfterViewInit {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet!: IonRouterOutlet;

  private cookiesService = inject(CookieService);
  private settingsService = inject(SettingsService);

  ngAfterViewInit(): void {
    this.settingsService.getCookies().subscribe(storedCookies => {
      if (!storedCookies) {
        this.cookiesService.openCookieModal(this.routerOutlet, storedCookies);
      }
    });
  }
}
