import { Language, LanguageCode, languageCodeList } from '@model/language';

import { Constants } from '../constants';

export const processLanguageCode = (
  input: LanguageCode | string | undefined,
  languages: Language[],
): Language | undefined => {
  // Create an array of the const with all language codes.
  const languageCodeArray = Array.from(languageCodeList);
  // Process the given input paramater as a LanguageCode. If not the result is undefined
  const inputLanguageCode: LanguageCode | undefined = input as LanguageCode;
  // Check if the found LanguageCode is inside available list of language codes
  // If not in the list get the default code from constants
  const processedInput = languageCodeArray.includes(inputLanguageCode)
    ? inputLanguageCode
    : Constants.DEFAULT_LANGUAGE_CODE;

  // Return the Language connected the processed input from the list of Languages
  return languages.find(language => language.code === processedInput);
};
