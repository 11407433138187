import { inject, Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular/standalone';
import { Language, languageLabel } from '@model/language';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@service/settings.service';
import { mergeMap, Observable, of, tap } from 'rxjs';

import { processLanguageCode } from '../shared/language-util';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  languages: Language[] = Array.from(languageLabel.entries()).map(
    ([code, label]) => ({
      code,
      label,
      image: `/assets/icon/flag-${code}.png`,
    }),
  );

  private language?: Language;

  private actionSheetController = inject(ActionSheetController);
  private translateService = inject(TranslateService);
  private settingsService = inject(SettingsService);

  constructor() {
    this.translateService.addLangs(
      this.languages.map(language => language.code),
    );
  }

  init(): Observable<boolean> {
    return this.settingsService.getLanguageCode().pipe(
      tap(storedLanguageCode => {
        const language = processLanguageCode(
          storedLanguageCode ?? this.translateService.getBrowserLang(),
          this.languages,
        );
        if (language) {
          this.language = language;
          this.translateService.use(language.code);
          this.translateService.setDefaultLang(language.code);
        }
      }),
      mergeMap(() => of(true)),
    );
  }

  getLanguage(): Language | undefined {
    return this.language;
  }

  getLanguages(): Language[] {
    return this.languages;
  }

  async selectLanguage(): Promise<Language | undefined> {
    const header = this.translateService.instant('language.select.title');
    const actionSheet = await this.actionSheetController.create({
      header,
      buttons: this.languages.map(language => ({
        text: language.label,
        data: language,
      })),
    });

    await actionSheet.present();

    const { data: language } = await actionSheet.onWillDismiss<
      Language | undefined
    >();

    if (language) {
      this.settingsService.setLanguageCode(language.code).subscribe();
      this.translateService.use(language.code).subscribe();
    }

    return language;
  }
}
