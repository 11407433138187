import { ProvisionType, Water } from '@svnl/shared';

export const filterWaters = (waters: Water[], filter: string): Water[] => {
  const query = filter.toLowerCase();

  return waters
    .filter(water => {
      return (
        (water.name && water.name.toLowerCase().includes(query)) ||
        (water.organisationName &&
          water.organisationName.toLowerCase().includes(query)) ||
        (water.organisationCity &&
          water.organisationCity.toLowerCase().includes(query)) ||
        (water.description && water.description.toLowerCase().includes(query))
      );
    })
    .sort((a, b) => {
      const aIndex = a.name.toLowerCase().indexOf(query);
      const bIndex = b.name.toLowerCase().indexOf(query);

      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      } else if (aIndex !== -1) {
        return -1;
      } else if (bIndex !== -1) {
        return 1;
      } else {
        return 0;
      }
    });
};

export const waterHasProvisionTypes = (
  water: Water,
  ...provisionTypes: ProvisionType[]
): boolean =>
  water.provisions.length > 0
    ? water.provisions.some(provision =>
        provisionTypes.includes(provision.type),
      )
    : false;
