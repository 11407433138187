import { Routes } from '@angular/router';
import { skipStartGuard } from '@service/skip-start-guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full',
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.routes').then(m => m.routes),
  },
  {
    path: 'start',
    canActivate: [skipStartGuard],
    loadChildren: () => import('./start/start.routes').then(m => m.routes),
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.routes').then(m => m.routes),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];
