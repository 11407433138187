import { computed, Injectable, signal } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private connectionStatus = signal<ConnectionStatus | undefined>(undefined);

  isConnected = computed<boolean>(
    () => this.connectionStatus()?.connected ?? false,
  );

  async init(): Promise<void> {
    const status = await Network.getStatus();

    if (status) {
      this.connectionStatus.set(status);
    }

    Network.addListener('networkStatusChange', connectionStatus => {
      this.connectionStatus.set(connectionStatus);
    });
  }
}
